import { Tooltip } from 'bootstrap'

class TableBrick {
    constructor(domElement = null) {
        this.container = $(domElement)

        this.initializeTooltips()
    }

    initializeTooltips() {
        this.container.find('.bootstrap-tooltip-container').each(function() {
            if (Tooltip.getInstance(this)) {
                Tooltip.getInstance(this).dispose()
            }
            
            new Tooltip(this, { 'container': this })
        })
    }
}

export default TableBrick