import imagesLoaded from 'imagesloaded'
import masonry from 'masonry-layout'

class MasonryGallery {
    constructor(domElement = null) {
        this.container = $(domElement)
        if(!this.container.length) {
            console.error('Please provide proper DOM Element for MasonryGallery')
            return
        }

        this.masonryGrid = null
        this.masonryContainer = this.container.find('.masonry-layout')

        this.init()
    }

    init() {
        const layout = this.masonryContainer[0]

        imagesLoaded(layout, () => {
            this.masonryGrid = new masonry(layout, {
                gutter: 1,
                columnWidth: 1,
                itemSelector: '.grid-item',
                percentPosition: true,
                //originLeft: false
            })
        })
    }
}

export default MasonryGallery
