/**
 * Content Pages list component
 */
import axios from 'axios'
import CategoryFilter from './CategoryFilter'
import Pagination from '../../partials/Pagination'
import { createUrlParamsFromObject } from '../../utils/qS'

class ContentPagesList {
    constructor(domElement = null) {
        this.container = $(domElement)

        if(!this.container.length) {
            console.error('Please provide propper DOM element for ContentPagesList class')
            return
        }

        this.data = {}
        this.brickId = this.container.data('id')

        // Category Filter
        this.CategoryFilter = null
        this.categoryFilterContainer = this.container.find('.content-pages-list--categories-container')
        if (this.categoryFilterContainer.length) {
            this.CategoryFilter = new CategoryFilter(this.categoryFilterContainer[0], {})
        }

        // List items
        this.listContainer = this.container.find('.content-pages-list--items')

        // Pagination component
        this.paginationContainer = this.container.find('.content-pages-list--pagination .pagination-container')
        this.Pagination = new Pagination(this.paginationContainer[0], {})

        this.onLoad()
        this.addEventListeners()
    }

    onLoad() {
        let category = 'all'

        if (this.CategoryFilter) {
            category = this.CategoryFilter.getSelectedCategory()
        }

        const page = this.Pagination.getCurrentPage()

        this.data = {
            page,
            category,
        }

        if (this.container.hasClass('news-list-areabrick-container')) {
            const searchInput = new URL(window.location.href).searchParams.get('searchInput')

            if (searchInput) {
                this.data = {
                    page,
                    searchInput
                }
            } else {
                this.data = {
                    page
                }
            }
        }
    }

    destroy() {
        this.Pagination.destroy()
        this.Pagination = null

        if (this.CategoryFilter) {
            this.CategoryFilter.destroy()
            this.CategoryFilter = null
        }
    }

    addEventListeners() {
        this.Pagination.on('pageChange', ({ data }) => this.handlePageChange(data))

        if (this.CategoryFilter) {
            this.CategoryFilter.on('categoryChange', ({ data }) => this.handleCategoryChange(data))
        }
    }

    /**
     * Selected category change
     * @param {Number|String} event
     */
    handleCategoryChange(categoryId = null) {
        const selectedCategory = categoryId === 'all' ? null : categoryId

        // Reset page
        this.Pagination.setCurrentPage(1)

        this.data = {
            page: 1,
            category: selectedCategory
        }

        this.handleChangeAction()
    }

    /**
     * Pagination page change
     * @param {Number} page
     */
    handlePageChange(page = 1) {
        this.data = {
            ...this.data,
            page
        }

        $([document.documentElement, document.body]).animate({
            scrollTop: this.container.offset().top
        }, 400)

        this.handleChangeAction()
    }

    /**
     * Action handler for view update
     * [Triggered by page or category change]
     */
    async handleChangeAction() {
        try {
            this.Pagination.disable()
            if (this.CategoryFilter) {
                this.CategoryFilter.disable()
            }

            this.container.find('.loader-container').removeClass('d-none')
            const requestUri = this.createRequestUri()

            // Make request
            const response = await axios.get(requestUri)

            const { data } = response
            this.updateViewData(data)

            this.Pagination.enable()
            if (this.CategoryFilter) {
                this.CategoryFilter.enable()
            }
        } catch(err) {
            this.Pagination.enable()
            if (this.CategoryFilter) {
                this.CategoryFilter.enable()
            }

            this.container.find('.loader-container').addClass('d-none')

            console.error(err)
            throw err
        }

    }

    /**
     * Update view with new data
     */
    updateViewData(responseData = {}) {
        // Fetch response for this brick
        const $response = $('<div />').append(responseData)
        const $brickResponse = $response.find(`.content-pages-list--container[data-id="${this.brickId}"]`)

        // Update categories
        if (this.CategoryFilter) {
            const $categoriesResponse = $brickResponse.find('.content-pages-list--categories-container')
            const categoriesContent = $categoriesResponse.html()

            this.categoryFilterContainer.html(categoriesContent)
        }

        // Update list content
        const $listResponse = $brickResponse.find('.content-pages-list--items')
        const listContent = $listResponse.html()

        this.listContainer.html(listContent)

        // Update pagination
        const $paginationResponse = $brickResponse.find('.content-pages-list--pagination .pagination')
        const { pageCount } = $paginationResponse.data()

        this.Pagination.setPageCount(pageCount)

        if (pageCount > 1) {
            this.container.find('.content-pages-list--pagination').removeClass('d-none')
        } else {
            this.container.find('.content-pages-list--pagination').addClass('d-none')
        }
    }

    /**
     * Create request uri and update browser url
     * @returns {String}
     */
    createRequestUri() {
        const querries = createUrlParamsFromObject(this.data)
        const url = window.location.href.split('?')[0]

        let requestUri = querries ? `${url}?${querries}` : url

        // Update browser url
        if (typeof window.history.pushState != 'undefined') {
            window.history.pushState(null, null, requestUri)
        }

        return requestUri
    }
}

export default ContentPagesList
