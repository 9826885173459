/**
 * ContentBundle main script file
 */
// jQuery
import $ from 'jquery'
window.$ = window.jQuery = $

// Components
import ContentPagesList from './components/ContentPagesList'
import MasonryGallery from './components/MasonryGallery'
import FAQSearch from './faq/FaqSearch'

$('.content-pages-list--container').each(function() {
    new ContentPagesList(this)
})

$('.masonry-gallery').each(function() {
    new MasonryGallery(this)
})

$('.faq-search-container').each(function() {
    new FAQSearch(this)
})


